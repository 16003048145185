<template>
	<div class="member-list">

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>就诊数据列表</span></el-col>
				<el-col style="display: flex;justify-content: flex-end;">
					<el-button type="primary" size="small" @click="getlist()">
						查询
					</el-button>
					
					<!-- <el-button class="btn-add" size="mini" @click="goplugdetails()">
						添加
					</el-button> -->
				</el-col>
			</el-row>
		</el-card>
		<el-card class="filter-container" shadow="never" style="margin: 20px 0;">
			<el-table :data="list" style="width: 100%;"  height="950">
				<el-table-column  fixed label="姓名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="手机号" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.mobile }}</span>
					</template>
				</el-table-column>
			
				
				<el-table-column label="身份证号" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.idCard }}</span>
					</template>
				</el-table-column>
				<el-table-column label="性别" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.sex==0">{{''}}</span>
						<span  v-if="scope.row.sex==1">{{ '男' }}</span>
						<span  v-if="scope.row.sex==2">{{  '女' }}</span>
						<span  v-if="scope.row.sex==3">{{ '未知'}}</span>
						
						<!-- <span>{{ scope.row.sex ==3 ? '未知' : scope.row.sex ==1 ? '男' : '女' }}</span> -->
					</template>
				</el-table-column>
				<el-table-column label="年龄" align="center">
					<template slot-scope="scope">
						<span>{{  scope.row.age}}</span>
					</template>
				</el-table-column>
				<el-table-column label="身高" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.height }}</span>
					</template>
				</el-table-column>
				<el-table-column label="体重" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.weight }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="血型" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.bloodType }}</span>
					</template>
				</el-table-column>
				<el-table-column label="RH" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.rh }}</span>
					</template>
				</el-table-column>
		<!-- 		<el-table-column label="食物过敏" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.foodAllergy }}</span>
					</template>
				</el-table-column>
				<el-table-column label="药物过敏" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.drugAllergy }}</span>
					</template>
				</el-table-column> -->
				
				<el-table-column label="过敏史" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.allergyHistory || '无'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="既往病史" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.pastMedicalHistory }}</span>
					</template>
				</el-table-column>
				
				
				<el-table-column label="户籍所在地" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.householdAddress }}</span>
					</template>
				</el-table-column>
				<el-table-column label="户口性质" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.registeredType }}</span>
					</template>
				</el-table-column>
				<el-table-column label="现住址" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.address }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="婚否" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.marryStatus }}</span>
					</template>
				</el-table-column>
		<!-- 		<el-table-column label="妻子电话" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.wifeTel }}</span>
					</template>
				</el-table-column> -->
				
				<el-table-column label="配偶电话" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.sex=='1' ">{{  scope.row.mobile }}</span>
						<span v-if="scope.row.sex=='2' ">{{  scope.row.husbandTel }}</span>
						<span v-if="scope.row.sex!='1'&& scope.row.sex!='2' ">{{  scope.row.husbandTel }}</span>
					</template>
				</el-table-column>
				<el-table-column label="民族" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.nation }}</span>
					</template>
				</el-table-column>
				<el-table-column label="学历" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.education}}</span>
					</template>
				</el-table-column>
				<el-table-column label="职业" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.occupation }}</span>
					</template>
				</el-table-column>
				<el-table-column label="岗位" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.post }}</span>
					</template>
				</el-table-column>
				<el-table-column label="工作单位" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.company }}</span>
					</template>
				</el-table-column>
				<el-table-column label="幼儿园名称" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.kindergartenName }}</span>
					</template>
				</el-table-column>
				<el-table-column label="注册时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click.native.prevent="del(scope)">删除</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
	

	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '', //搜索
				list: [], //循环列表 
		
				box: [], //全部数据
			
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true,
				query:[]
			}
		},
		methods: {
			onSubmit() {
			},
			
			// 单个删除
			del(e) {
				const that = this;
                this.$confirm('您确认要删除此就诊人么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/member/patient_del', {
                        patientId: e.row.id
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
			},
	
			
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/member/state',{memberId:e.row.memberId}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},

			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/member/patient', {
					
					memberId:that.query.memberId
				}).then(res => {
					
					that.list = res.data;
					
			
				})
			},



		},
		created() {
			const that = this;
			that.query=that.$route.query;
			that.getlist();

		}
	}
</script>

<style lang="less" scoped>


</style>
